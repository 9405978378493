//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    goHome() {
      this.$router.push('/');
    },
  },
};
